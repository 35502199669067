/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class Terms extends React.Component {

  render() {
    return (
      <>
        <footer className=" footer">
        <Container>
          <Row className=" row-grid align-items-center mb-5">
            <Col lg="12">
            <h1>Website Terms and Conditions of Use</h1>
<h2>1. Terms</h2>
<p>By accessing this Website, accessible from postgen.io, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trade mark law.</p>
<h2>2. Use License</h2>
<p>Permission is granted to temporarily download one copy of the materials on PostGen's Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>
<ul>
<li>modify or copy the materials;</li>
<li>use the materials for any commercial purpose or for any public display;</li>
<li>attempt to reverse engineer any software contained on PostGen's Website;</li>
<li>remove any copyright or other proprietary notations from the materials; or</li>
<li>transferring the materials to another person or "mirror" the materials on any other server.</li>
</ul>
<p>This will let PostGen to terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format.</p>
<h2>3. Disclaimer</h2>
<p>All the materials on PostGen’s Website are provided "as is". PostGen makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, PostGen does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.</p>
<h2>4. Limitations</h2>
<p>PostGen or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on PostGen’s Website, even if PostGen or an authorize representative of this Website has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.</p>
<h2>5. Revisions and Errata</h2>
<p>The materials appearing on PostGen’s Website may include technical, typographical, or photographic errors. PostGen will not promise that any of the materials in this Website are accurate, complete, or current. PostGen may change the materials contained on its Website at any time without notice. PostGen does not make any commitment to update the materials.</p>
<h2>6. Links</h2>
<p>PostGen has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by PostGen of the site. The use of any linked website is at the user’s own risk.</p>
<h2>7. Site Terms of Use Modifications</h2>
<p>PostGen may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.</p>
<h2>8. Your Privacy</h2>
<p>Please read our <a href="/privacy-policy">Privacy Policy</a>.</p>
<h2>9. Governing Law</h2>
<p>Any claim related to PostGen's Website shall be governed by the laws of us without regards to its conflict of law provisions.</p>
            </Col>
          </Row>
        </Container>
        </footer>
      </>
    );
  }
}

export default Terms;
