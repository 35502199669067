/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import classnames from "classnames";
import custom from './custom.css';
import GoogleLogin from 'react-google-login';
import ls from 'local-storage';

// reactstrap components
import { Button, Container, Row, Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, Card, CardHeader, CardBody, CardFooter, CardImg, Form, BadgeUncontrolledCollapse, Badge,
DropdownMenu,
DropdownItem,
DropdownToggle,
UncontrolledDropdown,
UncontrolledCollapse,
Media,
NavbarBrand,
Navbar,
NavItem,
NavLink,
Nav,
UncontrolledTooltip } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";
import banner from '../assets/img/brand/banner.png';
import banner_white from '../assets/img/brand/banner_white.png';

// index page sections
import Hero from "./IndexSections/Hero.js";
import Buttons from "./IndexSections/Buttons.js";
import Inputs from "./IndexSections/Inputs.js";
import CustomControls from "./IndexSections/CustomControls.js";
import Menus from "./IndexSections/Menus.js";
import Navbars from "./IndexSections/Navbars.js";
import Tabs from "./IndexSections/Tabs.js";
import Progress from "./IndexSections/Progress.js";
import Pagination from "./IndexSections/Pagination.js";
import Pills from "./IndexSections/Pills.js";
import Labels from "./IndexSections/Labels.js";
import Alerts from "./IndexSections/Alerts.js";
import Typography from "./IndexSections/Typography.js";
import Modals from "./IndexSections/Modals.js";
import Datepicker from "./IndexSections/Datepicker.js";
import TooltipPopover from "./IndexSections/TooltipPopover.js";
import Carousel from "./IndexSections/Carousel.js";
import Icons from "./IndexSections/Icons.js";
import Login from "./IndexSections/Login.js";
import Download from "./IndexSections/Download.js";

var qs = require('qs');

const options = {
      autoClose: 5000,
      hideProgressBar: true,
      position: "bottom-right",
};

const default_url = "https://www.linkedin.com/posts/dan-martell_free-model-these-30-saas-pitch-decks-to-activity-6790172112259690496-M4i0";

class App extends React.Component {
  //postRef = React.createRef();

  constructor() {
    super()

    this.loadUser = this.loadUser.bind(this);
    this.getUserInfo = this.getUserInfo.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkoutVerify = this.checkoutVerify.bind(this);
    //this.postRef = React.createRef()

    if(ls.get('googleId') != null && ls.get('googleId') != undefined)
    {
      this.state = {
        isLoggedIn: true,
        image: ls.get('imageUrl'),
        first_name: ls.get('first_name'),
        last_name: ls.get('last_name'),
        googleId: ls.get('googleId'),
        postURL: '',
        credits: 30,
        formName: ls.get('first_name'),
        formEmail: ls.get('email'),
        formMessage: '',
      };

      console.log("reading params");
      var params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      console.log(params);
      if(params.session_id)
      {
        console.log("session_id there");
        this.checkoutVerify(params.session_id);
      }

      //getUserInfo
      this.getUserInfo();
    }
    else{
      this.state = {
        isLoggedIn: false,
        image: '',
        first_name: '',
        last_name: '',
        postURL: '',
      };
    }

    var save = '';
    var wait = true;

    setInterval(() => {
      //console.log(save);
      this.getTableData();
    }, 20000);
  }

  getUserInfo() {
    // fetch getUserInfo
    // load state with credits and more
    fetch(`/api/getUserInfo?googleId=${ls.get('googleId')}`).then(res => res.json()).then(data => {
      console.log("user info received");
      this.loadUser(data);
    });
    this.getTableData();
  };

  checkoutVerify(session_id) {
    fetch(`/api/confirmPayment?googleId=${ls.get('googleId')}&session_id=${session_id}`).then(res => res.json()).then(plan => {
      if(plan && plan != false)
      {
        // update state (tier, credits)
        this.setState({
          tier: plan.tier,
          credits: plan.credits,
        });

        // make toast (use tier name, credits)
        toast.success(`Success! Your account has been upgraded to the ${plan.name} tier - we've added ${plan.credits} email credits to your account.`, options);
      }
    });
  };

  getTableData() {
    if(this.state.isLoggedIn)
    {
      fetch(`/api/getTableData?googleId=${ls.get('googleId')}`).then(res => res.json()).then(data => {
        console.log("got table data");
        console.log(data.rows);
        this.setState({
          table: data.rows
        })
      });
    }
  };

  loadUser(data) {
    console.log(data);
    const {credits, email, tier} = data;
    this.setState({
      credits: credits,
      email: email,
      tier: tier,
    });
    console.log("user data loaded");
  };

  renderIcon() {
    var firstName = ls.get('first_name');
    var lastName = ls.get('last_name');
    var image = ls.get('imageUrl');
    if(this.state.isLoggedIn)
    {
     return (
        <img src={image} width="25" height="25" alt=""/>
      )
    }
  }

  renderName() {
    var firstName = ls.get('first_name');
    var lastName = ls.get('last_name');
    var image = ls.get('imageUrl');
    if(this.state.isLoggedIn)
    {
     return (
        <>{firstName}</>
      )
    }
    else {
      return (
        <><span style={{fontFamily: "Roboto"}}>Sign in with Google </span></>
      )
    }
  }

  handleChange(event) {
    this.setState({postURL: event.target.value});
  }

  handleFormChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit() {
    fetch(`/api/sendCustom?name=${this.state.formName}&email=${this.state.formEmail}&message=${encodeURIComponent(this.state.formMessage)}`).then(res => res.json()).then(data => {
      console.log("submitted custom message!");
      this.setState({formMessage: ''});
    });
  }

  sample = "https://www.linkedin.com/embed/feed/update/urn:li:share:6780529429702561792";
  sample2 = "https://www.linkedin.com/posts/dan-martell_free-model-these-30-saas-pitch-decks-to-activity-6790172112259690496-M4i0";

  state = {};

  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
    //console.log(this.refs.postRef.current);
    //console.log(ReactDOM.findDOMNode(this.postRef));
    //console.log(ReactDOM.findDOMNode(this.postRef).value);
    //console.log(this.postRef.current)
    //console.log(this.postRef.current.value)
  };
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  getEmailsToggle = url => {

    if(this.state.isLoggedIn)
    {
      toast.warning("Scanning link...", options);

      console.log("url: " + url);

      fetch(`/api/parseURL?googleId=${ls.get('googleId')}&url=${url}`).then(res => res.json()).then(data => {

        toast.dismiss();

        if(data == false)
        {
          //send toast
          toast.error("That link is invalid! Copy the link from a LinkedIn post", options);
          console.log('invalid url format');
        }
        else {
          const {ogTitle, ogImage, ogDescription, likes} = data;
          console.log("Likes: " + likes);
          this.setState({
            ogTitle: ogTitle,
            ogImage: ogImage.url,
            ogDescription: ogDescription,
            likes: likes,
            scrapeURL: url
          })
          this.toggleModal("previewModal");
        }
      });
    }
    else{
      toast.error("Log in to use PostGen!", options);
    }
  };

  confirmScrape = (lastModal, retry) => {
    // turn off existing Modal
    this.toggleModal(lastModal);

    // render confirmation Modal
    if(retry || this.state.credits >= this.state.likes)
    {
      this.toggleModal("notificationModal");
      fetch(`/api/launchScript?googleId=${ls.get('googleId')}&postLink=${this.state.scrapeURL}&likes=${this.state.likes}&postTitle=${this.state.ogDescription}`).then(res => res.json()).then(data => {
        console.log("launching script!");
        this.setState({
          postURL: '',
          credits: Math.max(0, this.state.credits - this.state.likes),
        })
      });
      // include likes to post to show as "profilecount"
    }
    else if(this.state.credits == 0)
    {
      this.toggleModal("creditsModal");
    }
    else
    {
      this.toggleModal("warningModal");
    }
  };

  renderScrapeCount(scrapecount, profilecount, complete) {
    if(complete)
    {
      return (
        <td style={{textAlign: "center"}}>{profilecount}</td>
      )
    }
    else if(scrapecount == 0)
    {
      return (
        <td style={{textAlign: "center"}}><i>{profilecount} queued</i></td>
      )
    }
    else
    {
      return (
        <td style={{textAlign: "center"}}>{scrapecount} / {profilecount}</td>
      )
    }
  }

  renderTableData() {
    if(!this.state.table){
      return (
        <tr>
          <td><a href="#app">Try out PostGen first to collect contact emails!</a></td>
          <td style={{textAlign: "center"}}>30</td>
          <td style={{textAlign: "center"}}><a href="#app"><i className="ni ni-cloud-download-95" /></a></td>
        </tr>
      )
    }
    else{
      return this.state.table.map((job, index) => {
        var { postlink, posttitle, profilecount, scrapecount, outputlink, step, complete, error } = job;
        return (
          <tr>
            <td><a href={postlink} target="_blank">{posttitle}</a></td>
            {this.renderScrapeCount(scrapecount, profilecount, complete)}
            <td style={{textAlign: "center"}}><a href={outputlink} target="_blank">{complete && <i className="ni ni-cloud-download-95" />}</a></td>
          </tr>
        )
      });
    }
    return (
      <>
        <tr>
          <td><a href="">Naman Singh posted on LinkedIn: Litty yeayea travis scott burger something gets it lets go go upwards wow its going wow</a></td>
          <td style={{textAlign: "center"}}>1,123</td>
          <td style={{textAlign: "center"}}><a href=""><i className="ni ni-cloud-download-95" /></a></td>
        </tr>
        <tr>
          <td><a href="">Naman Singh posted on LinkedIn: Litty yeayea travis scott burger something gets it lets go go upwards wow its going wow</a></td>
          <td style={{textAlign: "center"}}>1,123</td>
          <td style={{textAlign: "center"}}><a href=""><i className="ni ni-cloud-download-95" /></a></td>
        </tr><tr>
          <td><a href="">Naman Singh posted on LinkedIn: Litty yeayea travis scott burger something gets it lets go go upwards wow its going wow</a></td>
          <td style={{textAlign: "center"}}>1,123</td>
          <td style={{textAlign: "center"}}><a href=""><i className="ni ni-cloud-download-95" /></a></td>
        </tr><tr>
          <td><a href="">Naman Singh posted on LinkedIn: Litty yeayea travis scott burger something gets it lets go go upwards wow its going wow</a></td>
          <td style={{textAlign: "center"}}>1,123</td>
          <td style={{textAlign: "center"}}><a href=""><i className="ni ni-cloud-download-95" /></a></td>
        </tr><tr>
          <td><a href="">Naman Singh posted on LinkedIn: Litty yeayea travis scott burger something gets it lets go go upwards wow its going wow</a></td>
          <td style={{textAlign: "center"}}>1,123</td>
          <td style={{textAlign: "center"}}><a href=""><i className="ni ni-cloud-download-95" /></a></td>
        </tr>
      </>
    )
  }

  render() {

    const responseGoogle = (response) => {
      ls.set('googleId', response.googleId);
      ls.set('email', response.profileObj.email);
      ls.set('first_name', response.profileObj.givenName)
      ls.set('last_name', response.profileObj.familyName)
      ls.set('imageUrl', response.profileObj.imageUrl)

      window.heap.identify(response.profileObj.email);
      window.heap.addUserProperties({'name': `${response.profileObj.givenName} ${response.profileObj.familyName}`, 'first_name': response.profileObj.givenName, 'last_name': response.profileObj.familyName, 'email': response.profileObj.email});

      this.setState({
        isLoggedIn: true,
        googleId: response.googleId,
        image: response.profileObj.imageUrl,
        email: response.profileObj.email,
        first_name: response.profileObj.givenName,
        last_name: response.profileObj.givenName,
        credits: 30,
      });
      console.log(response);
      console.log("googleId set!");

      fetch(`/api/login?googleId=${response.profileObj.googleId}&first_name=${response.profileObj.givenName}&last_name=${response.profileObj.familyName}&email=${response.profileObj.email}`).then(res => res.json()).then(data => {
        console.log("login data sent");
        this.loadUser(data);
      });

      this.getTableData();
    }

    const failGoogle = (err) => {
      console.log(err);
    }

    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container>
              <NavbarBrand className="mr-lg-2" to="/" tag={Link}>
                <img
                  alt="..."
                  src={banner_white}
                  height="200px"
                />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="..."
                          src={require("assets/img/brand/banner_white.png")}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    href="#about"
                  >
                    <span className="nav-link-inner--text">About</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    href="#pricing"
                  >
                    <span className="nav-link-inner--text">Pricing</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    href="#contact"
                  >
                    <span className="nav-link-inner--text">Contact Us</span>
                  </NavLink>
                </NavItem>
                </Nav>
                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  {this.state.isLoggedIn &&
                    <NavItem className="d-none d-lg-block ml-lg-4">
                      <Button
                        className="btn-neutral btn-icon"
                        color="default"
                        href="#pricing"
                      >
                        <span className="nav-link-inner--text ml-1">
                          {new Intl.NumberFormat('en-US').format(this.state.credits)} Email Credits
                        </span>
                      </Button>
                    </NavItem>
                  }
                  <NavItem className="d-none d-lg-block ml-lg-4">
                    <GoogleLogin
                      clientId="853524927851-d1lp2scl4l52uu1e00j31s4r0t57pahf.apps.googleusercontent.com"
                      buttonText="Sign in with Google"
                      onSuccess={responseGoogle}
                      onFailure={failGoogle}
                      cookiePolicy={'single_host_origin'}
                    >{this.renderName()}{' '}{this.renderIcon()}</GoogleLogin>
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>




        <main ref="main">


        <section className="section section-lg section-shaped">
          <div className="shape shape-style-1 shape-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="py-md">
            <Row className="row-grid justify-content-between align-items-center">
            <Col lg="6">
              <h1 className="display-1 text-white">
                Get Emails From LinkedIn Posts
                <span className="text-white display-3">Your highest quality sales pipeline is a few clicks away.</span>
              </h1>
              <p className="lead text-white">
                For any LinkedIn post, <b>PostGen</b> gives you verified emails of people who liked/commented on it. Crafting an organic outreach strategy has never been easier.
              </p>
            </Col>
            <Col className="mb-lg-auto" lg="5">
              <div className="transform-perspective-right">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="pr-md-5 text-center">
                      <img src={banner} height="60px" align=""/>
                      <h3 className="text-primary" style={{marginBottom:"-20px", marginTop:"10px"}} >Use in 3 Easy Steps:</h3>
                      <ul className="list-unstyled mt-5" style={{textAlign:"left"}}>
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <Badge
                                className="badge-circle mr-3"
                                color="success"
                              >
                                <i className="ni ni-ungroup" />
                              </Badge>
                            </div>
                            <div>
                              <h6 className="mb-0">
                                Copy link from a LinkedIn post
                              </h6>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <Badge
                                className="badge-circle mr-3"
                                color="success"
                              >
                                <i className="ni ni-single-copy-04" />
                              </Badge>
                            </div>
                            <div>
                              <h6 className="mb-0">Paste link into <span className="text-primary" style={{fontSize:"20px"}}><b>{" "}PostGen</b></span></h6>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <Badge
                                className="badge-circle mr-3"
                                color="success"
                              >
                                <i className="ni ni-cloud-download-95" />
                              </Badge>
                            </div>
                            <div>
                              <h6 className="mb-0">
                                Download file with all email contacts!
                              </h6>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>


            </Row>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>




          <section
            className="section section-components pb-0"
            id="app"
          >
            <Container>
              <Row className="justify-content-center">

                            <Col lg="12">
                              <h3 className="display-4 text-primary">
                                Paste link to a LinkedIn Post and get emails:{" "}
                              </h3>
                              <FormGroup style={{marginBottom:"-20px"}}>
                                <InputGroup className="mb-4">
                                  <Input
                                    id="postURL"
                                    placeholder="LinkedIn Post URL"
                                    type="text"
                                    value={this.state.postURL}
                                    onFocus={e => this.setState({ searchFocused: true })}
                                    onBlur={e => this.setState({ searchFocused: false })}
                                    onChange={this.handleChange}
                                  />
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" style={{margin:"0"}} onClick={() => this.getEmailsToggle(this.state.postURL)}>Get Emails</Button>
                                    <Modal
                                      className="modal-dialog-centered"
                                      isOpen={this.state.previewModal}
                                      toggle={() => this.toggleModal("previewModal")}
                                    >
                                      <div className="modal-body text-center">
                                        <h5 style={{marginBottom: "30px", marginTop:"10px"}}>
                                        Is this the post you wish to get emails from?
                                        </h5>
                                        <a href={this.state.postURL} target="_blank">
                                        <Card className="card-lift--hover shadow border-0" style={{textAlign: "left"}}>
                                          <CardHeader className="bg-secondary" style={{paddingBottom:"0px", paddingTop:"10px"}}>
                                          <h6><b>{this.state.ogTitle}</b></h6>
                                          </CardHeader>
                                          <CardBody className="text-default bg-white" style={{paddingBottom:"0px", paddingTop:"0px"}}>
                                            <img src={this.state.ogImage} style={{margin: "0px"}} width="100%"/>
                                            <p style={{marginTop: "15px"}}>
                                            {this.state.ogDescription}
                                            </p>
                                          </CardBody>
                                        </Card>
                                        </a>
                                        <p className="lead text-italic text-primary" style={{marginBottom: "0px", marginTop:"40px"}}>
                                          <b>{this.state.likes}</b> contacts to be scraped.
                                        </p>
                                      </div>
                                      <div className="modal-footer">
                                        <Button color="primary" type="button" onClick={() => this.confirmScrape("previewModal")}>
                                          Yes, Get Emails!
                                        </Button>
                                        <Button
                                          className="ml-auto"
                                          color="link"
                                          data-dismiss="modal"
                                          type="button"
                                          onClick={() => this.toggleModal("previewModal")}
                                        >
                                          Close
                                        </Button>
                                      </div>
                                    </Modal>
                                    <Modal
                                      className="modal-dialog-centered modal-success"
                                      contentClassName="bg-gradient-success"
                                      isOpen={this.state.notificationModal}
                                      toggle={() => this.toggleModal("notificationModal")}
                                    >
                                      <div className="modal-header">
                                        <h6 className="modal-title" id="modal-title-notification">
                                          Success!
                                        </h6>
                                        <button
                                          aria-label="Close"
                                          className="close"
                                          data-dismiss="modal"
                                          type="button"
                                          onClick={() => this.toggleModal("notificationModal")}
                                        >
                                          <span aria-hidden={true}>×</span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <div className="py-3 text-center">
                                          <i className="ni ni-spaceship ni-3x" />
                                          <h2 className="text-white">Scraping Emails...</h2>
                                          <p>
                                            Once the job is finished, we'll send you an email with a file containing all email contacts from the LinkedIn post!
                                          </p>
                                        </div>
                                      </div>
                                      <div className="modal-footer">
                                        <Button className="btn-white text-success" color="default" type="button" onClick={() => this.toggleModal("notificationModal")}>
                                          Ok, Got it
                                        </Button>
                                        <Button
                                          className="text-white ml-auto"
                                          color="link"
                                          data-dismiss="modal"
                                          type="button"
                                          onClick={() => this.toggleModal("notificationModal")}
                                        >
                                          Close
                                        </Button>
                                      </div>
                                    </Modal>
                                    <Modal
                                      className="modal-dialog-centered modal-warning"
                                      contentClassName="bg-gradient-warning"
                                      isOpen={this.state.warningModal}
                                      toggle={() => this.toggleModal("warningModal")}
                                    >
                                      <div className="modal-header">
                                        <h6 className="modal-title" id="modal-title-notification">
                                          Warning
                                        </h6>
                                        <button
                                          aria-label="Close"
                                          className="close"
                                          data-dismiss="modal"
                                          type="button"
                                          onClick={() => this.toggleModal("warningModal")}
                                        >
                                          <span aria-hidden={true}>×</span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <div className="py-3 text-center">
                                          <i className="ni ni-atom ni-3x" />
                                          <h2 className="text-white">Low on Credits!</h2>
                                          <p>
                                            The post you want to scrape has <b>{this.state.likes}</b> contacts - you only have <b>{this.state.credits}</b> credits remaining!
                                          </p>
                                          <p>
                                             You may purchase more credits, or scrape only <b>{this.state.credits}</b> contacts with your remaining credits.
                                          </p>
                                        </div>
                                      </div>
                                      <div className="modal-footer">
                                        <Button className="btn-white text-warning" color="default" type="button" onClick={() => this.confirmScrape("warningModal", true)}>
                                          Scrape Anyways
                                        </Button>
                                        <Button
                                          className="btn-white ml-auto"
                                          color="link"
                                          data-dismiss="modal"
                                          type="button"
                                          href="#pricing"
                                          onClick={() => this.toggleModal("warningModal")}
                                        >
                                          Buy More Credits
                                        </Button>
                                      </div>
                                    </Modal>
                                    <Modal
                                      className="modal-dialog-centered modal-danger"
                                      contentClassName="bg-gradient-danger"
                                      isOpen={this.state.creditsModal}
                                      toggle={() => this.toggleModal("creditsModal")}
                                    >
                                      <div className="modal-header">
                                        <h6 className="modal-title" id="modal-title-notification">
                                          Oops!
                                        </h6>
                                        <button
                                          aria-label="Close"
                                          className="close"
                                          data-dismiss="modal"
                                          type="button"
                                          onClick={() => this.toggleModal("creditsModal")}
                                        >
                                          <span aria-hidden={true}>×</span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <div className="py-3 text-center">
                                          <i className="ni ni-fat-remove ni-3x" />
                                          <h2 className="text-white">Out of Credits!</h2>
                                          <p>
                                            You'll need to purchase more email credits to scrape the {this.state.likes} contacts from this post! See our <b><a className="text-white" href="#pricing">Pricing plans</a></b>.
                                          </p>
                                        </div>
                                      </div>
                                      <div className="modal-footer">
                                        <Button className="btn-white text-danger" color="default" type="button" href="#pricing" onClick={() => this.toggleModal("creditsModal")}>
                                          Buy More Credits
                                        </Button>
                                        <Button
                                          className="text-white ml-auto"
                                          color="link"
                                          data-dismiss="modal"
                                          type="button"
                                          onClick={() => this.toggleModal("creditsModal")}
                                        >
                                          Close
                                        </Button>
                                      </div>
                                    </Modal>
                                  </InputGroupAddon>
                                </InputGroup>
                              </FormGroup>
                              <div className="btn-wrapper" style={{align: "center", marginBottom:"60px"}}>
                                <Button color="info" href="#contact">
                                  Help
                                </Button>
                                <Button
                                  outline
                                  className="btn-white"
                                  color="default"
                                  to="/register-page"
                                  onClick={() => this.getEmailsToggle(default_url)}
                                >
                                  Try An Example
                                </Button>
                              </div>
                            </Col>
              </Row>
            </Container>
          </section>
          {this.state.table && this.state.table.length > 0 && <section className="section section-lg pt-0" id="downloads">
            <Container>
              <h4 className="mb-3">
                <span>My Downloads:</span>
              </h4>
              <Card className="bg-white shadow-lg border-0">
                <div className="p-5">
                  <Row className="align-items-center">
                    <Col lg="12">
                      <table style={{width: "100%"}}>
                        <tr>
                          <th>LinkedIn Post</th>
                          <th width="20%" style={{textAlign: "center"}}>Contacts</th>
                          <th width="20%" style={{textAlign: "center"}}>Download</th>
                        </tr>
                        {this.renderTableData()}
                      </table>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Container>
          </section>}

          <section className="section bg-secondary" id="about">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="6">
                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("assets/img/theme/img-1-1200x1000.jpg")}
                      top
                    />
                    <blockquote className="card-blockquote">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-bg"
                        preserveAspectRatio="none"
                        viewBox="0 0 583 95"
                      >
                        <polygon
                          className="fill-default"
                          points="0,52 583,95 0,95"
                        />
                        <polygon
                          className="fill-default"
                          opacity=".2"
                          points="0,42 583,95 683,0 0,95"
                        />
                      </svg>
                      <h4 className="display-3 font-weight-bold text-white">
                        High Conversion Leads
                      </h4>
                      <p className="lead text-italic text-white">
                        PostGen supercharges your sales pipeline. With just a few clicks, you're able to retrieve emails from highly relevant, highly active leads.
                      </p>
                    </blockquote>
                  </Card>
                </Col>
                <Col md="6">
                  <div className="pl-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-5">
                      <i className="ni ni-settings" />
                    </div>
                    <h3 style={{marginTop:"-15px"}}>PostGen works like <i>magic</i></h3>
                    <p className="lead">
                      Our scripts search through all people who have liked/commented on a post you request; then we scrape individual LinkedIn profiles to determine your leads' email addresses. Within minutes, you'll have a download of all email contacts!
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="section section-lg bg-gradient-default" id="pricing">
            <Container className="pb-300">
              <Row className="text-center justify-content-center" style={{marginTop:"-40px", marginBottom:"30px"}}>
                <Col lg="10">
                  <h2 className="display-1 text-white">Flexible Pricing</h2>
                </Col>
              </Row>
              <Row className="text-center justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="shadow-lg border-0">
                        <CardBody className="py-5">
                          <h5 className="text-success text-uppercase" style={{marginTop:"-20px"}}>
                            <i className="ni ni-user-run icon icon-shape icon-shape-success rounded-circle mb-4"/>{" "} Starter
                          </h5>
                          <h4 className="text-success" style={{marginTop:"-15px"}}>
                            <span style={{fontSize:"65px"}}>$19</span> / monthly
                          </h4>
                          <h6 className="mt-3" style={{textAlign: "left"}}>
                            <ul>
                              <li style={{fontSize: "20px"}}><b>1,000 emails</b> / month</li>
                              <li>Unlimited Post Scraping</li>
                              <li>Export to CSV/Excel</li>
                            </ul>

                          </h6>

                          <Button
                            className="mt-4"
                            size="lg"
                            color="success"
                            href="/api/checkoutSession?price=2"
                          >
                            Get Started
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <h5 className="text-primary text-uppercase" style={{marginTop:"-20px"}}>
                            <i className="ni ni-istanbul icon icon-shape icon-shape-primary rounded-circle mb-4"/>{" "} Pro
                          </h5>
                          <h4 className="text-primary" style={{marginTop:"-15px"}}>
                            <span style={{fontSize:"65px"}}>$75</span> / monthly
                          </h4>
                          <h6 className="mt-3" style={{textAlign: "left"}}>
                            <ul>
                              <li style={{fontSize: "20px"}}><b>5,000 emails</b> / month</li>
                              <li>Unlimited Post Scraping</li>
                              <li>Export to CSV/Excel</li>
                              <li>Bulk Processing</li>
                              <li>Live Chat Support</li>
                            </ul>
                          </h6>

                          <Button
                            className="mt-4"
                            size="lg"
                            color="primary"
                            href="/api/checkoutSession?price=3"
                          >
                            Purchase
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <h5 className="text-warning text-uppercase" style={{marginTop:"-20px"}}>
                            <i className="ni ni-planet icon icon-shape icon-shape-warning rounded-circle mb-4"/>{" "} Enterprise
                          </h5>
                          <h4 className="text-warning" style={{marginTop:"-15px"}}>
                            <span style={{fontSize:"65px"}}>$230</span> / monthly
                          </h4>
                          <h6 className="mt-3" style={{textAlign: "left"}}>
                            <ul>
                              <li style={{fontSize: "20px"}}><b>20,000 emails</b> / month</li>
                              <li>Unlimited Post Scraping</li>
                              <li>Export to CSV/Excel</li>
                              <li>Bulk Processing</li>
                              <li>Dedicated Support Agent</li>
                              <li>Salesforce, Outreach, Zapier Integrations</li>
                            </ul>
                          </h6>

                          <Button
                            className="mt-4"
                            size="lg"
                            color="warning"
                            href="/api/checkoutSession?price=4"
                          >
                            Purchase
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg pt-lg-0 section-contact-us" id="contact">
            <Container style={{marginBottom:"-50px"}}>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Need more flexibility?</h4>
                      <p className="mt-0">
                        We want to make sure we meet your needs - please reach out to us!
                      </p>
                      <FormGroup
                        className={classnames("mt-5", {
                          focused: this.state.nameFocused
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-circle-08" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Your name"
                            value={this.state.formName}
                            onChange={(e) => this.setState({ formName: e.target.value })}
                            type="text"
                            onFocus={e => this.setState({ nameFocused: true })}
                            onBlur={e => this.setState({ nameFocused: false })}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: this.state.emailFocused
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email address"
                            value={this.state.formEmail}
                            onChange={(e) => this.setState({ formEmail: e.target.value })}
                            type="email"
                            onFocus={e => this.setState({ emailFocused: true })}
                            onBlur={e => this.setState({ emailFocused: false })}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-4">
                        <Input
                          className="form-control-alternative"
                          cols="80"
                          name="name"
                          value={this.state.formMessage}
                          onChange={(e) => this.setState({ formMessage: e.target.value })}
                          placeholder="Type a message..."
                          rows="4"
                          type="textarea"
                        />
                      </FormGroup>
                      <div>
                        <Button
                          block
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="button"
                          onClick={() => this.handleSubmit()}
                        >
                          Send Message
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
          <ToastContainer />
        </main>
        <footer className="footer has-cards">
          <Container>

          <Row className="align-items-center justify-content-md-between">
            <Col md="6">
              <div className="copyright">
                © {new Date().getFullYear()}{" "}
                  PostGen
              </div>
            </Col>
            <Col md="6">
              <Nav className="nav-footer justify-content-end">
                <NavItem>
                  <NavLink
                    href="/privacy-policy"
                    target="_blank"
                  >
                    Privacy Policy
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="/terms-of-service"
                    target="_blank"
                  >
                    Terms of Service
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default App;
